import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import emailjs from "emailjs-com";
import i18next from "i18next";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import address from "./assets/address.png";
import ags from "./assets/ags-logo.webp";
import arrow from "./assets/arrow.png";
import b2 from "./assets/b2.png";
import b3 from "./assets/b3.png";
import b4 from "./assets/b4.png";
import background from "./assets/background.webp";
import gh from "./assets/gh.webp";
import t1 from "./assets/t1.png";
import t2 from "./assets/t2.png";
// import t3 from "./assets/t3.png";
import t4 from "./assets/t4.png";
import vardagrologo from "./assets/vardagro-logo.webp";
import logo from "./assets/logo.png";
import IS1 from "./assets/IS1.png";
import IS2 from "./assets/IS2.png";
import IS3 from "./assets/IS3.png";
import IS4 from "./assets/IS4.png";

function App() {
  const { t } = useTranslation();
  const dropDown = useRef();
  const form = useRef();
  const [sendBtn, setSendBtn] = useState(true);
  const [lang, setLang] = useState(localStorage.getItem("lang") || "am");

  const setDropdownState = (state) => {
    dropDown.current["style"]["display"] = state;
  };
  const lanChange = () => {
    const currDisplay = dropDown.current["style"]["display"];
    if (currDisplay === "flex") {
      setDropdownState("none");
    } else {
      setDropdownState("flex");
    }
  };
  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem("lang") || "am");
    window.onscroll = () => {
      setDropdownState("none");
    };
  }, []);
  useEffect(() => {
    setDropdownState("none");
  }, [lang]);
  const sendEmail = (e) => {
    e.preventDefault();
    setSendBtn(false);

    const promise = new Promise((res, rej) => {
      emailjs
        .sendForm(
          "service_psg08so",
          "template_e3h3b4h",
          form.current,
          "pkBlVgccrplw5IN5M"
        )
        .then(
          () => {
            setSendBtn(true);
            form.current.reset();
            res();
          },
          (error) => {
            console.log(error);
            rej();
          }
        );
    });
    toast.promise(promise, {
      pending: "Promise is pending",
      success: "Success 👌",
      error: "Please try again later",
    });
  };

  return (
    <div className="container">
      <div
        className="header"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 10000,
        }}
      >
        <div className="inner">
          <img className="logo" src={logo} alt="" />

          <nav>
            <SmoothLink href={"home"} text={t("HomeLink")} />
            <SmoothLink href={"about-us"} text={t("AboutUs")} />
            <SmoothLink href={"solutions"} text={t("Solutions")} />
            <SmoothLink href={"partners"} text={t("Partners")} />
            {/* <SmoothLink href={"prices"} text={t("Prices")} /> */}
            <SmoothLink href={"contact-us"} text={t("Contacts")} />
          </nav>

          <button onClick={lanChange} className="ENG">
            {t("eng")}
          </button>
        </div>
      </div>
      <div id="Ldropdown" ref={dropDown} className="languages-dropdown">
        <img className="arrow" src={arrow} alt="" />
        <ChangeLangLink lang={"en"} text={t("english")} cb={setLang} />
        <ChangeLangLink lang={"am"} text={t("armenian")} cb={setLang} />
        <ChangeLangLink lang={"ru"} text={t("russian")} cb={setLang} />
      </div>
      <div className="main" id="home" style={{ marginTop: "70px" }}>
        <div className="info">
          <p className="pre1">{t("Title")}</p>
          <p className="pre2">{t("theSmart")}</p>

          {/* <button>{t("ReadMore")}</button> */}
        </div>
        <img src={background} alt="" />
      </div>
      <div className="about-us" id="about-us">
        <p className="au-p">{t("AboutUs2")}</p>
        <div className="au-content">
          <img src={gh} alt="" />

          <div className="au-text">
            <p>{t("smartGreen")}</p>
            <p className="longP">{t("ourCompany")}</p>
            <p>{t("whatDoWe")}</p>
            <p className="longP">{t("whatDoWeText")}</p>
            <p>{t("WhyChoose")}</p>
            <p className="longP">{t("WhyChooseText")}</p>
          </div>
        </div>
      </div>
      <div className="inpvation-S" id="solutions">
        <p className="is-p">{t("InSolutions")}</p>
        <div className="cards">
          <div className="card">
            <img src={IS1} alt="" />
            <p className="card-txt">{t("GreenSim")}</p>
          </div>
          <div className="card">
            <img src={IS2} alt="" />
            <p className="card-txt">{t("ControlItems")}</p>
          </div>
          <div className="card">
            <img src={IS3} alt="" />
            <p className="card-txt">{t("RtMonitoring")}</p>
          </div>
          <div className="card">
            <img src={IS4} alt="" />
            <p className="card-txt">{t("TempSensors")}</p>
          </div>
        </div>
      </div>
      <div className="work-with" id="partners">
        <div className="ww-txt">
          <p className="ww-pre1">{t("BestPartners")}</p>
          <p className="ww-pre2">{t("BestPartnersText")}</p>
        </div>
        <a href="http://vardagro.am/" rel="noreferrer" target="_blank">
          <img className="imgVardA" src={vardagrologo} alt="" />
        </a>
        <a href="/" rel="noreferrer">
          <img src={ags} alt="" />
        </a>
      </div>
      <div className="contact-us" id="contact-us">
        <p className="cu-txt1">{t("ContactUs")}</p>
        <p className="cu-txt2">{t("ContactUsText")}</p>

        <div className="contact-part">
          <div className="contact-info">
            <div className="top-part">
              <p>{t("ContactInfo")}</p>
              <p className="CP-pre">{t("ContactInfoText")}</p>
              <div className="t-icons">
                <a href="/" rel="noreferrer">
                  <img src={t1} alt="" />
                  Armenia, Yerevan
                </a>
                <a href="tel:+37455610333" rel="noreferrer" target="_blank">
                  <img src={t2} alt="" />
                  +(374) 55-61-0333
                </a>
                {/* <a
                  href="https://t.me/senior_vova"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={t3} alt="" />
                  Senior Vova
                </a> */}
                <a
                  href="mail:smartgreen.am@gmail.com"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={t4} alt="" />
                  smartgreen.am@gmail.com
                </a>
              </div>
            </div>

            {/* <a className="mapimg" href="">
                <img src={address} alt="" />
              </a> */}
            <div className="b-icons">
              {/* <a href="" target="_blank">
                <img className="b1" src={b1} alt="" />
              </a> */}
              <a
                href="https://www.instagram.com/smartgreen.am/"
                rel="noreferrer"
                target="_blank"
              >
                <img className="b2" src={b2} alt="" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100086849613892"
                rel="noreferrer"
                target="_blank"
              >
                <img className="b3" src={b3} alt="" />
              </a>
              <a
                href="https://www.linkedin.com/company/smartgreen-llc"
                rel="noreferrer"
                target="_blank"
              >
                <img className="b4" src={b4} alt="" />
              </a>
            </div>
          </div>

          <form ref={form} onSubmit={sendEmail}>
            <div className="input-part">
              <div className="input-cards">
                <p>{t("Name")}</p>
                <input type="text" name="fname" required />
              </div>
              <div className="input-cards">
                <p>{t("LastName")}</p>
                <input type="text" name="lname" required />
              </div>
              <div className="input-cards">
                <p>{t("Email")}</p>
                <input type="email" name="from_email" required />
              </div>
              <div className="input-cards">
                <p>{t("Phone")}</p>
                <input type="text" name="from_tel" required />
              </div>
              <div className="input-cards">
                <p>{t("Message")}</p>
                <textarea
                  required
                  name="message"
                  className="messeg-input"
                  cols="30"
                  rows="5"
                ></textarea>
              </div>
              <button disabled={!sendBtn} type="submit">
                {t("SendButton")}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="footer">
        <p>SmartGreen LLC, 2022 - 2024</p>
      </div>
      <ToastContainer position={"bottom-center"} />
    </div>
  );
}

const ChangeLangLink = ({ lang, text, cb }) => {
  const onChangeLanguage = (l) => {
    localStorage.setItem("lang", l);
    i18next.changeLanguage(l);
    cb(l);
  };

  return (
    <>
      <a
        href="/"
        onClick={(e) => {
          e.preventDefault();
          onChangeLanguage(lang);
        }}
        className="dropdawnTxt"
      >
        {text}
      </a>
    </>
  );
};

const SmoothLink = ({ href, text }) => {
  return (
    <Link
      activeClass="active"
      to={href}
      spy={true}
      smooth={true}
      offset={-120}
      duration={500}
    >
      {text}
    </Link>
  );
};

export default App;
