import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import i18next from "i18next";
import translationEN from "./locales/en/translation.json";
import translationRU from "./locales/ru/translation.json";
import translationAM from "./locales/am/translation.json";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "ru", "am"],
    fallbackLng: "am",
    detection: {
      order: ["localStorage", "htmlTag"],
      caches: ["localStorage"],
    },
    resources: {
      en: {
        translation: translationEN,
      },
      ru: {
        translation: translationRU,
      },
      am: {
        translation: translationAM,
      },
    },
    debug: false,
  });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
